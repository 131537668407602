<template>
	<div class="notice-wrap">
		<div class="page_title"><strong>采购信息</strong>（共{{total}}条）</div>
		<div class="sort_set">
			<!-- 排序筛选区 -->
			<div class="filters">
			    <div class="sort">
			        <div class="item" @click="changeSort('id')">
			            <div class="item-name">综合</div>
			            <i
			                v-if="filters.order === 'id' && filters.sort === 'desc'"
			                class="el-icon-arrow-down el-icon--down"
			            />
			            <i v-else class="el-icon-arrow-up el-icon--up" />
			        </div>
			        <div class="item" @click="changeSort('create_date')">
			            <div class="item-name">发布日期</div>
			            <i
			                v-if="filters.order === 'create_date' && filters.sort === 'desc'"
			                class="el-icon-arrow-down el-icon--down"
			            />
			            <i v-else class="el-icon-arrow-up el-icon--up" />
			        </div>
			        <div class="item" @click="changeSort('end_date')">
			            <div class="item-name">报价截止日期</div>
			            <i
			                v-if="filters.order === 'end_date' && filters.sort === 'desc'"
			                class="el-icon-arrow-down el-icon--down"
			            />
			            <i v-else class="el-icon-arrow-up el-icon--up" />
			        </div>
			    </div>
			   <!-- <div class="search">
			        <el-input
			            placeholder="搜索 采购"
			            v-model="keyword"
			            maxlength="50"
			            class="input-with-select"
			            size="medium"
			            clearable
			        >
			            <el-button slot="append" icon="el-icon-search" @click="getShopList"></el-button>
			        </el-input>
			    </div> -->
			</div>
		</div>
		<table class="list_title">
			<tr>
				<td width="350" class="left">标题</td>
				<td width="138">截止日期</td>
				<td width="138">联合国组织机构</td>
				<td width="118">通知类型</td>
				<td width="200">参考编号</td>
				<td width="138">受惠国</td>
				<td width="120"></td>
			</tr>
		</table>
		<table class="list_item" v-for="(item, index) in purchaseList" :key="item.id">
			<tr>
				<td width="350" class="left">
					<div class="name_link" @click="toDetail(item.id)">{{item.purchase_name}}</div>
					<div>发布日期：{{item.create_date}}</div>
				</td>
				<td width="138">{{item.end_date}}</td>
				<td width="138">{{item.institution}}</td>
				<td width="118">{{item.type}}</td>
				<td width="200">{{item.sno}}</td>
				<td width="138">{{item.country}}</td>
				<td width="120"><button @click="toDetail(item.id)">详情信息</button></td>
			</tr>
		</table>
		<div class="list_page">
			<a href="https://www.ungm.org/Account/Registration" target="_blank"><img src="../../assets/images/purchase_login.png" class="purchase_login" border="0" /></a>
			<div class="pager">
			  <el-pagination
				background 
				:pager-count="1" 
				:total="total" 
				prev-text="上一页" 
				next-text="下一页" 
				:current-page.sync="currentPage" 
				:page-size.sync="pageSize" 
				@size-change="handlePageSizeChange" 
				@current-change="handleCurrentPageChange" 
				hide-on-single-page
			></el-pagination> 
			</div>
		</div>
	</div>
</template>

<script>
import http from "../../utils/http"

/**
 * 商品列表
 * @param {object} params
 */
export function getPurchasePage(params) {
    return http({
        url: "/api/purchase/page",
        data: params
    })
}

export default {
  name: "list",
  data: () => {
    return {
		purchaseList:[],
		total: 0,
		currentPage: 1,
		pageCount:1,
		pageSize: 12,
		loading:true,
		filters: {
		    order: "id",
		    sort: "desc"
		}
	};
  },
  created() {
	  this.getPurchaseList();
  },
  methods: {
	  getPurchaseList(){
		  const params = {
		      page: this.currentPage,
		      page_size: this.pageSize,
			  ...this.filters
		  }
		  getPurchasePage(params || {})
		  .then(res => {
		  		  const { count, page_count, list } = res.data
		  		  this.total = count
		  		  this.pageCount = page_count
		  		  this.purchaseList = list
		  		  this.loading = false
		  })
		  .catch(err => {
		  		  this.loading = false
		  })
	  },
	  handlePageSizeChange(size) {
	      this.pageSize = size
	      this.getPurchaseList()
	  },
	  handleCurrentPageChange(page) {
	      this.currentPage = page
	      this.getPurchaseList()
	  },
	  changeSort(type) {
	      this.filters.order = type
	      if (this.filters.order === type) {
	          this.$set(this.filters, "sort", this.filters.sort === "desc" ? "asc" : "desc")
	      } else {
	          this.$set(this.filters, "order", type)
	          this.$set(this.filters, "sort", "desc")
	      }
	      this.getShopList()
	  },
	  toDetail(purchase_id) {
	      this.$router.push({
	          path: "/purchase-" + purchase_id
	      })
	  }
  }
};
</script>
<style lang="scss" scoped>
.notice-wrap {
	border:1px solid #E5E5E5;
	margin-top:22px;
	padding-bottom:30px;
	background: #ffffff;
	overflow:hidden;
}
.page_title{width:100%;line-height:42px;border-bottom:1px solid #E5E5E5;padding-left:10px;}
.sort_set{
	width:100%;
	// height:40px;
	padding:6px 10px 6px 10px;
	background-color: #EEEEEE;
	border-bottom:1px solid #E5E5E5;
}

.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sort {
        display: flex;
        align-items: center;
        border: 1px solid #E5E5E5;

        .item {
			background:#fff;
            display: flex;
            align-items: center;
            padding: 3px 15px;
            border-right: 1px solid #E5E5E5;
            cursor: pointer;
            &:last-child {
                border: none;
            }
            &:hover {
                background: $base-color;
                color: #fff;
            }
        }
    }
}

.list_title{
	width:100%;
	height:34px;
	background:#F9F9F9;
	border-bottom:1px solid #E5E5E5;
	box-sizing: border-box;
}
.list_title td{text-align:center;}
.list_title td.left{text-align:left;padding-left:10px;box-sizing: border-box;}

.list_item{
	width:100%;
	height:34px;
	border-bottom:1px solid #E5E5E5;
	box-sizing: border-box;
}
.list_item td{text-align:center;padding:20px 0 20px 0;}
.list_item td.left{text-align:left;padding-left:10px;box-sizing: border-box;}
.list_item .name_link{color:#588CD7;cursor: pointer;}
.list_item button{background:#FF7800;border:1px solid #E36D16;color:white;padding:4px 10px 4px 10px;border-radius: 3px;}

.purchase_login{float:right;margin-right:28px;height:55px;margin-left:20px;}

.list_page{height:55px;	margin-top:30px;}
.list_page div.pager {
	text-align: center;
	padding-top:10px;
}
</style>
